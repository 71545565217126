<template>
    <div class="">
        <div v-if="page !== 'account'">
            <div v-if="listPremiumFeatures">
                <p class="text-muted f-14 mb-0">
                    Your {{ subscription.one_click_content.display }} membership does not include the features below. Upgrade
                    your membership to publish this worksheet.
                </p>
                <div v-for="(pf, index) in getPremiumFeatures" :key="'pf' + index" class="premium-feature my-3">
                    <span class="pf-icon">
                        <PremiumMarker :popover="false" :show="true"></PremiumMarker>
                    </span>
                    <span v-if="pf === 'font'">Font Choices</span>
                    <span v-if="pf === 'hw_font'">Premium Handwriting Fonts</span>
                    <span v-if="pf === 'image_uploading'">Uploaded Images</span>
                    <span v-if="pf === 'premium_icons'">Premium Icons</span>
                    <span v-if="pf === 'numbering'">Number Format</span>
                    <span v-else-if="pf === 'color'">Color Selection</span>
                    <span v-else-if="pf === 'private'">Saving as 'Private'</span>
                    <span v-else-if="pf === 'number'">Number Formatting</span>
                    <span v-else-if="pf === 'border'">Border Style</span>
                    <span v-else-if="pf === 'card_count'">
                        More than
                        {{ user.ab_tests['premium-bingo-cards'] }}
                        Bingo Cards
                    </span>
                    <span v-else-if="pf === 'fill_in_the_blank_style'">Letter Blank Style</span>
                </div>
            </div>
            <div v-else class="mb-3 text-muted f-14">
                <div v-if="user.user.has_plan">
                    <div v-if="subscription.name && subscription.name.includes('Unlimited Flashcards -') && isBingo">
                        You can only publish flashcards on your current plan. Become a premium member to create any type of
                        document you want!
                    </div>
                    <div v-if="subscription.name && subscription.name.includes('Unlimited Bingo -') && isFlashcard">
                        You can only publish bingo cards on your current plan. Become a premium member to create any type of
                        document you want!
                    </div>

                    <div
                        v-if="
                            (subscription.name && subscription.name.includes('Unlimited Worksheets')) ||
                            subscription.name.includes('Private Worksheets')
                        "
                    >
                        You can only publish worksheets on your current plan. Become a premium member to create any type of
                        document you want!
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="subscription.is_loading || subscription_status_loading"
            class="d-flex justify-content-center align-items-center body-loader"
        >
            <Loader :dark="true" size="30px"></Loader>
        </div>
        <div v-else class="mt-4">
            <template v-if="subscription.one_click_content.basic && isWorksheet">
                <div class="mb-4">
                    <OneClickSub
                        :plan="setUpgradeOption(subscription.one_click_content.basic)"
                        @change-plan="changePlan"
                    ></OneClickSub>
                </div>
            </template>
            <div v-for="(plan, index) in plans" :key="index">
                <div class="mb-4">
                    <OneClickSub :key="index" :plan="setUpgradeOption(plan)" @change-plan="changePlan"></OneClickSub>
                </div>
            </div>
            <div v-if="page !== 'account' && showSinglePrice" class="d-flex align-items-center justify-content-center mb-4">
                <b-button class="d-flex justify-content-center align-items-center" variant="success" @click="purchase">
                    <span class="f-14">Purchase Single Activity</span>
                </b-button>
                <div class="ml-3 w-75 description">
                    <p class="mb-0 f-13">
                        For a one time charge of $6, publish this activity. Good for special events, and if you won't need to
                        make more {{ brand }} activities.
                    </p>
                </div>
            </div>
            <div v-if="page !== 'account' && showRevertButton" class="d-flex align-items-center justify-content-center mb-4">
                <b-button
                    class="d-flex justify-content-center align-items-center"
                    variant="secondary"
                    @click="revertToBasic"
                >
                    <span class="f-14">Revert to Basic</span>
                </b-button>
                <div class="ml-3 w-75 description"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import EditPayWall from '../mixins/EditPayWall'
import OneClickSub from './OneClickSubscription.vue'
import { mapGetters } from 'vuex'
import Common from '../mixins/Common'
import OneClickUpgrade from '../mixins/OneClickUpgrade'
import { freeIcons, hasNonIconImages } from '../helpers/jsonObjectHelper'
import wmAlert from '../helpers/wmAlerts'
import Loader from '../widgets/Loader.vue'
import PremiumMarker from '../widgets/premium-marker.vue'

export default defineComponent({
    components: {
        OneClickSub,
        Loader,
        PremiumMarker,
    },
    mixins: [EditPayWall, Common, OneClickUpgrade],
    props: {
        page: {
            type: String,
            default: 'worksheet',
        },
        printing: {
            type: Boolean,
            default() {
                return false
            },
        },
    },
    computed: {
        ...mapGetters({
            isAdmin: 'user/isAdmin',
            getPremiumFeatures: 'document/getPremiumFeatures',
            isBingo: 'document/isBingo',
            isFlashcard: 'document/isFlashcard',
            isWorksheet: 'document/isWorksheet',
            worksheetImages: 'document/worksheetImages',
            flashcardImages: 'document/flashcardImages',
            bingoImages: 'document/bingoImages',
            entityType: 'document/entityType',
            enableRevertButton: 'abtests/enableRevertButton',
        }),
        brand() {
            return this.page
        },
        images() {
            if (this.entityType === 'worksheet') return this.worksheetImages
            if (this.entityType === 'flashcard') return this.flashcardImages
            if (this.entityType === 'bingo') return this.bingoImages
            return []
        },
        premiumImages() {
            // should return personal images and premium iconfinder images
            // todo: this should be updated after iconfinder implemented
            return this.images
        },
        hasPremiumImages() {
            return this.premiumImages?.length > 0 && hasNonIconImages(this.premiumImages)
        },
        isPrivate: {
            get() {
                return this.document.is_private
            },
        },
        plans() {
            let plans = []
            if (this.subscription.one_click_content.option_a) plans.push(this.subscription.one_click_content.option_a)
            if (this.subscription.one_click_content.option_b) plans.push(this.subscription.one_click_content.option_b)
            return plans.filter((plan) => {
                if (plan?.type !== 'premium' && !this.subscription.name) {
                    return !plan.downgrade
                } else if (
                    plan?.type !== 'premium' &&
                    !this.isWorksheet &&
                    ((this.subscription.name?.includes('Unlimited Bingo -') && this.isFlashcard) ||
                        (this.subscription.name?.includes('Unlimited Flashcards -') && this.isBingo))
                ) {
                    return !plan.downgrade
                } else if (
                    plan?.type !== 'premium' &&
                    this.isWorksheet &&
                    this.subscription.name?.includes('Private Worksheets -')
                ) {
                    return !plan.downgrade
                } else if (
                    plan?.type !== 'premium' &&
                    this.brand === 'account' &&
                    (!this.subscription.name || this.subscription.name?.includes('Private Worksheets -')) &&
                    !this.isWorksheet &&
                    !this.isBingo &&
                    !this.isFlashcard
                ) {
                    return !plan.downgrade
                }

                return !plan?.downgrade && plan?.type === 'premium'
            })
        },
        showRevertButton() {
            return this.isWorksheet && !this.document.is_published && this.enableRevertButton
        },
        listPremiumFeatures() {
            return this.isWorksheet && !this.document.is_published && this.getPremiumFeatures.length
        },
    },
    mounted() {
        this.$store.dispatch('subscription/getOneClickContent', this.page)
    },
    methods: {
        revertToBasic() {
            if (this.premiumImages.length >= 3) {
                wmAlert
                    .confirm({
                        html: `This will delete ${this.premiumImages.length - freeIcons(this.premiumImages).length} images from your document.`,
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.$store.dispatch('document/removePremiumFeatures')
                        }
                    })
            } else {
                this.$store.dispatch('document/removePremiumFeatures')
            }
        },
        goPremium() {
            this.showCheckoutModal(this.printing)
        },
    },
})
</script>

<style scoped>
.btn {
    min-width: 13rem;
}
.description p {
    line-height: 21.79px;
}
.body-loader {
    min-height: 200px;
}
</style>
